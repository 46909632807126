import React, { useRef, useState } from 'react';
import firebase from "gatsby-plugin-firebase";
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';

function ContactInfoContainer({ lang }) {

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const messageRef = useRef(null);
    const formRef = useRef(null);

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        let form = new FormData(formRef?.current);

        setSending(true);

        firebase.firestore()
            .collection('Contacts')
            .add({
                name: form.get("name"),
                email: form.get("email"),
                message: form.get("message"),
            })
            .then(() => {
                e.target.reset();
                setSuccess(true);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setSending(false);
            })
    }

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['contactInfoContainer'][expression];
    }

    return (
        <div className="container-fluid">

            {/* <!-- row --> */}
            <div className="row p-30-0">

                {/* <!-- col --> */}
                <div className="col-lg-12">

                    {/* <!-- section title --> */}
                    <div className="art-section-title">
                        {/* <!-- title frame --> */}
                        <div className="art-title-frame">
                            {/* <!-- title --> */}
                            <h4>{lang == 'fr' ? 'Contact informations' : "Let's get in touch!"}</h4>
                        </div>
                        {/* <!-- title frame end --> */}
                    </div>
                    {/* <!-- section title end --> */}

                </div>
                {/* <!-- col end --> */}
                {/* <!-- col --> */}
                <div className="col-lg-4">
                    {/* <!-- contact card --> */}
                    <div className="art-a art-card">
                        <div className="art-table p-15-15">
                            <ul>
                                <li>
                                    <h6>{echo('country')}: </h6><span>Cameroun</span>
                                </li>
                                <li>
                                    <h6>{echo('city')}:</h6><span>Bafoussam</span>
                                </li>
                                {/* <li>
                                    <h6>Adresse:</h6><span>Bafoussam</span>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    {/* <!-- contact card end --> */}
                </div>
                {/* <!-- col end --> */}
                {/* <!-- col --> */}
                <div className="col-lg-4">
                    {/* <!-- contact card --> */}
                    <div className="art-a art-card">
                        <div className="art-table p-15-15">
                            <ul>
                                {/* <li>
                                    <h6>Email:</h6><span>contat@webmaster.kouelab.com</span>
                                </li> */}
                                <li>
                                    <h6>TWitter :</h6><span>@hanniel.tsasse</span>
                                </li>
                                <li>
                                    <h6>Facebook:</h6><span>Hanniel Tsasse</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- contact card end --> */}
                </div>
                {/* <!-- col end --> */}
                {/* <!-- col --> */}
                <div className="col-lg-4">
                    {/* <!-- contact card --> */}
                    <div className="art-a art-card">
                        <div className="art-table p-15-15">
                            <ul>
                                <li>
                                    <h6>Support:</h6><span>+237 6 54 49 15 97</span>
                                </li>
                                {/* <li>
                                    <h6>Bureau:</h6><span>+237 6 91 61 74 84</span>
                                </li> */}
                                <li>
                                    <h6>{echo("personnal")}:</h6><span>+237 6 91 61 74 84</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- contact card end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-lg-12">

                    {/* <!-- section title --> */}
                    <div className="art-section-title">
                        {/* <!-- title frame --> */}
                        <div className="art-title-frame">
                            {/* <!-- title --> */}
                            <h4>{echo("contact")}</h4>
                        </div>
                        {/* <!-- title frame end --> */}
                    </div>
                    {/* <!-- section title end --> */}

                    {/* <!-- contact form frame --> */}
                    <div className="art-a art-card">

                        {/* <!-- contact form --> */}
                        <form ref={formRef} onSubmit={handleSubmit} className="art-contact-form">
                            {/* <!-- form field --> */}
                            <div className="art-form-field">
                                {/* <!-- name input --> */}
                                <input ref={nameRef} name="name" className="art-input" type="text" placeholder={echo("your.name")} required />
                                {/* <!-- label --> */}
                                <label htmlFor="name"><i className="fas fa-user"></i></label>
                            </div>
                            {/* <!-- form field end --> */}
                            {/* <!-- form field --> */}
                            <div className="art-form-field">
                                {/* <!-- email input --> */}
                                <input ref={emailRef} name="email" className="art-input" type="email" placeholder="Email" required />
                                {/* <!-- label --> */}
                                <label htmlFor="email"><i className="fas fa-at"></i></label>
                            </div>
                            {/* <!-- form field end --> */}
                            {/* <!-- form field --> */}
                            <div className="art-form-field">
                                {/* <!-- message textarea --> */}
                                <textarea ref={messageRef} name="message" className="art-input" placeholder="Message" required></textarea>
                                {/* <!-- label --> */}
                                <label htmlFor="message"><i className="far fa-envelope"></i></label>
                            </div>
                            {/* <!-- form field end --> */}
                            {/* <!-- button --> */}
                            <div className="art-submit-frame">

                                <button
                                    disabled={sending}
                                    className={["art-btn art-btn-md art-submit", success ? 'art-submit-out' : ''].join(' ')}
                                    type="submit"
                                >
                                    {sending ? <span>{echo("sending")}...</span> : <span>{echo('send')}</span>}
                                </button>

                                {success == true && <div className="art-success">{echo('success')} <i className="fas fa-check"></i></div>}
                            </div>
                        </form>
                        {/* <!-- contact form end --> */}

                    </div>
                    {/* <!-- contact form frame end --> */}

                </div>
                {/* <!-- col end --> */}

            </div>
            {/* <!-- row end --> */}

        </div>
    )
}

export default ContactInfoContainer
